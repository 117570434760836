@use 'sass:color';
@import '../../scss/colors';
@import '../../scss/mixins';

.container-primary {
  @include dropdown-item-container('primary');
}

.container-secondary {
  @include dropdown-item-container('secondary');
}


.selected-primary {
  background-color: map-get($colors, 'faintW');
  color: map-get($colors, 'baseD');
}

.selected-secondary {
  background-color: map-get($colors, 'faintsS2Back');
  color: map-get($colors, 'baseD');
}