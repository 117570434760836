@use 'sass:color';
@import '../../scss/colors';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.2031rem 1rem;
  gap: 0.25rem;
  background: map-get($colors, 'faints_2WD');
  border-radius: 0.875rem;
}

.all {
  cursor: pointer;
  padding: 0.5625rem 1.25rem;
}

.page {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.5;
  border-radius: 0.5rem;
  width: 2rem;
  height: 2rem;
  cursor: pointer;

  &.active {
    background-color: map-get($colors, 'accsent_1Wd');
    opacity: 1;
  }
}