@import '../../../scss/mixins';

.focused label {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.suggestion-list {
  @include dropdown-list-container();
  margin-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}