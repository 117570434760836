@use "sass:color";
@import "../../scss/colors";
@import "../../scss/mixins";

.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.625rem;
  width: 100%;
  @include custom-scroll-bar();
}

.full-width {
  width: 100%;
}

.select-primary {
  @include dropdown-container("primary");
}

.select-secondary {
  @include dropdown-container("secondary");
}

.selected-text {
  color: map-get($colors, "baseD");
  display: flex;
  flex: 1;

  &-placeholder {
    color: map-get($colors, "faintsS");
  }
}

.item-list-primary {
  @include dropdown-list-container("primary");
}

.item-list-secondary {
  @include dropdown-list-container("secondary");
}

.item {
  display: flex;
  align-items: center;
  padding: 0.75rem 0.875rem;
  gap: 0.375rem;
}

.input-error,
.selected-text-error {
  color: map-get($colors, "accsentWd");
}

.error-wrapper {
  display: flex;
  align-items: center;
  padding: 0 0.875rem;
  gap: 0.3125rem;
}

.error-text {
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1rem;
}
