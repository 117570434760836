@import "../../scss/colors";
@import "../../scss/dimensions";

.container {
  display: flex;
  align-items: center;
  gap: 24px;
}

.right {
  width: auto;
}

.input label {
  background-color: map-get($colors, "faintS2");
}

@media screen and (max-width: $tablet-screen-width) {
  .container {
    flex-direction: column;
  }
}