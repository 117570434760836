@import "../../../scss/dimensions";
@import "../../../scss/colors";

.container {
  border-bottom: 1px solid map-get($colors, "faintsW");
  padding: 10px 0;
}

.top {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 7px;
}

.bottom {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 19px;
}

button.btn {
  width: auto;
  margin-left: auto;
  color: white;
  border: none;
  background-color: map-get($colors, 'accsent_3S');

  &.success {
    background-color: map-get($colors, 'accsent_2S');
  }

  &.checking {
    background-color: map-get($colors, 'faintsS');
    cursor: default;

    &.for-teacher {
      cursor: pointer;
    }
  }
}

.course-info-container {
  display: flex;
  align-items: center;
  gap: 19px;
}

.course-info {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.icon {
  flex-shrink: 0;

  &.checking {
    & rect {
      fill: map-get($colors, 'faintsS');
    }
  }
}

@media screen and (max-width: $mobile-screen-width) {
  .container {
    border: none;
    background-color: map-get($colors, "baseW");
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0px 2px 6px 0px rgba(130, 169, 188, 0.08),
      0px 5px 50px 0px rgba(0, 67, 101, 0.08);
    margin-bottom: 12px;
  }
}

@media screen and (max-width: $tablet-screen-width) {
  .btn {
    width: 100%;
  }
}
